import { globalPostService } from "../../utils/globalApiServices"
import { checkApiStatus, setGlobalCookie, checkEnvironment, getLocalStorageInfo } from "../../utils"
import { trackLogin } from "../../utils/mixpanel"
import config from '../../config'
// API Call
export function loginApi(props, data = {}) {
  this.setState({ formSubmitLoader: true })
  globalPostService("api/login/", data).then((response) => {
    this.setState({ formSubmitLoader: false })
    if (checkApiStatus(response)) {
      localStorage.setItem('userInfo', JSON.stringify(response.data.data));
      let baseDomain = '.sparta.aero';
      let expireAfter = new Date();
      expireAfter.setDate(expireAfter.getDate() + 3);
      let cookieData = JSON.stringify({ access: response.data.data.access, environment: config.env.key })
      setGlobalCookie("lessorAccess", cookieData, 3)
      setGlobalCookie("domain", baseDomain, 3)
      props.history.push("/select-lessor")
      this.props.enqueueSnackbar("Login Successful.", {variant: "success",anchorOrigin: { vertical: "top", horizontal: "right" }})
    }else{
      
    }
  })
}

export function lessorUpdateApi(props, data = {}, reloadWindow = false, isLoadernotNeeded = false) {
  if(!isLoadernotNeeded){this.setState({ formSubmitLoader: true })}
  globalPostService(`api/user-authorization/`, data).then((response) => {
    if(!isLoadernotNeeded) { this.setState({ formSubmitLoader: false }); }
    if (checkApiStatus(response)) {
      let userDetail = getLocalStorageInfo();
        userDetail = {
          ...userDetail,
          access: response.data.data.lessor_access_token,
          defaultLessor: response.data.data.current_lessor,
          user: response.data.data.user
        }
        let baseDomain = '.sparta.aero';
        let expireAfter = new Date();
        expireAfter.setDate(expireAfter.getDate() + 3);
        let cookieData = JSON.stringify({access:response.data.data.lessor_access_token, id:response.data.data.current_lessor.id, refresh:'', environment: config.env.key })
        let cookieName = JSON.stringify({name:response.data.data.user.name, designation:response.data.data.user.designation})
        setGlobalCookie('userName', cookieName, 3)
        setGlobalCookie("lessorAccess", cookieData, 3)
        setGlobalCookie("domain", baseDomain, 3)
        localStorage.setItem("userInfo", JSON.stringify(userDetail))
        props.history.push("/assets-listing")
    }
  })
}
export function signUpApi(props = {}, data) {
  globalPostService("api/sign-up/", data).then((response) => {
    if (checkApiStatus(response)) {
      props.history.push("/otp-verification/" + response.data.data.token)
      localStorage.setItem("userEmail", data.email)
      this.props.enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      })
    } else {
      this.props.enqueueSnackbar(response.data.message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      })
    }
  })
}
export function OTPVerifyApi(props = {}, data) {
  globalPostService("api/pass-verify-otp/", data).then((response) => {
    if (checkApiStatus(response)) {
      localStorage.clear()
      props.history.push("/login")
      this.props.enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      })
    } else {
      this.props.enqueueSnackbar(response.data.message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      })
    }
  })
}
export function OTPResendApi(props = {}, data) {
  globalPostService("api/pass-resend-otp/", data).then((response) => {
    if (checkApiStatus(response)) {
      this.props.enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      })
    } else {
      this.props.enqueueSnackbar(response.data.message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      })
    }
  })
}
export function forgotApi(props, data = {}) {
  this.setState({ formSubmitLoader: true })
  globalPostService(`api/password/reset/`, data).then((response) => {
    if (checkApiStatus(response)) {
      this.props.enqueueSnackbar("Email Sent Successfully.", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      })
      props.history.push("/login")
    } else {
      this.props.enqueueSnackbar(response.data.message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      })
    }
    this.setState({ formSubmitLoader: false })
  })
}
export function resetPasswordApi(props, data = {}) {
  this.setState({ formSubmitLoader: true })
  globalPostService(`api/password/reset-confirm/`, {
    ...data,
    token: props.match.params.token.split("_")[1],
    uSlug: props.match.params.token.split("_")[0],
  }).then((response) => {
    if (checkApiStatus(response)) {
      props.history.push("/login")
      this.props.enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      })
    }
    this.setState({ formSubmitLoader: false })
  })
}
// State Update Related Functions
export function updateAuthInfoState(value, key) {
  this.setState((prevState) => ({
    ...prevState,
    authInfo: {
      ...prevState.authInfo,
      [key]: value,
    },
    error: {
      ...prevState.error,
      [key]: "",
    },
  }))
}
