export const dashboardFilterOptions = {
    'esn': {
        'inputType': 'text',
        'title': 'Serial Number',
    },
    'owner': {
        'inputType': 'text',
        'title': 'Owner',
    },
    'lessee': {
        'inputType': 'dropdown',
        'title': 'Lessee',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'slug'
    },
    'engine_type': {
        'inputType': 'dropdown',
        'title': 'Engine Type',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
        'multiple': true
    },
    'country': {
        'inputType': 'text',
        'title': 'Country',
    },
}

