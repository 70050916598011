import { getLocalStorageInfo } from "../../../../utils"

export const srTableMainHead = [
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },

  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "Repair Compliance", label: "Repair Compliance", colspan: 3 },

  { id: "", label: "", sortOption: false },

  { id: "Last Done", label: "Last Done", colspan: 3 },

  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
]

export const SrTableHead = [
  { id: "actions", label: "Action", sortOption: false },
  { id: "ata_chapter", label: "ATA Chapter", sortOption: true },
  { id: "repair_ref_number", label: "Repair Ref No.", sortOption: true },
  {
    id: "repair_type",
    label: "Repair Type",
    sortOption: true,
  },
  {
    id: "repair_description",
    label: "Repair Description",
    sortOption: true,
  },
  { id: "repeat_inspection", label: "Repair Inspection", sortOption: false },
  { id: "repair_location", label: "Repair Location", sortOption: false },
  { id: "repair_detail", label: "Repair Detail", sortOption: false },
  {
    id: "repair_compliance_ref",
    label: "Repair Compliance Ref",
    sortOption: false,
  },
  {
    id: "repair_compliance_date",
    label: "Date",
    sortOption: false,
  },
  { id: "repair_compliance_tsn", label: "TSN", sortOption: false },
  { id: "repair_compliance_csn", label: "CSN", sortOption: false },

  {
    id: "repair_compliance_date",
    label: "Repair Next Due",
    sortOption: false,
  },

  { id: "last_done_date", label: "Date", sortOption: false },
  { id: "last_done_fh", label: "FH", sortOption: false },
  { id: "last_done_fc", label: "FC", sortOption: false },

  { id: "remarks", label: "Remarks" },
  { id: "attachments", label: "Attachments" },
]

export const assetFilterOps = {
  aircraft_type: {
    inputType: "dropdown",
    placeholder: "Select Aircraft Type",
    title: "Aircraft Type",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  engine_type: {
    inputType: "dropdown",
    placeholder: "Select Engine Type",
    title: "Engine Type",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  lessee: {
    inputType: "dropdown",
    placeholder: "Select Lessee",
    title: "Lessee",
    options: [],
    labelKey: "name",
    valueKey: "slug",
    multiple: true,
  },
  lessor_name: {
    inputType: "text",
    placeholder: "Enter Lessor",
    title: "Lessor",
  },
  owner: {
    inputType: "text",
    placeholder: "Enter Owner ",
    title: "Owner",
  },
  ownership_type: {
    inputType: "dropdown",
    placeholder: "Select Ownership Type",
    title: "Ownership",
    options: [
      { label: "Owned", value: 1 },
      { label: "Managed", value: 2 },
    ],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
  registration: {
    inputType: "text",
    placeholder: "Enter Registeration Number",
    title: "Registeration Number",
  },
  portfolio: {
    inputType: "text",
    placeholder: "Enter Portfolio",
    title: "Portfolio",
  },
  serial_number: {
    inputType: "text",
    placeholder: "Enter Serial Number",
    title: "Serial Number",
  },
  status: {
    inputType: "dropdown",
    placeholder: "Select Status",
    title: "Status",
    options:
      getLocalStorageInfo() &&
      getLocalStorageInfo().defaultLessor &&
      getLocalStorageInfo().defaultLessor.id === 442
        ? [
            { label: "Prospect", value: "11" },
            { label: "In Storage", value: "10" },
            { label: "Lease Return", value: "9" },
            { label: "MOU Signed", value: "8" },
            { label: "MOU Issued", value: "7" },
            { label: "Lease Issued", value: "6" },
            { label: "Archive", value: "5" },
            { label: "Written Off/ Sold / Part out", value: "4" },
            { label: "OnGround", value: "3" },
            { label: "Off Lease", value: "2" },
            { label: "On Lease", value: "1" },
          ]
        : [
            { label: "Archive", value: "5" },
            { label: "On Lease", value: "1" },
            { label: "Off Lease", value: "2" },
            { label: "On Ground", value: "3" },
            { label: "Written Off/ Sold / Part out", value: "4" },
          ],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
}
