import React, { Fragment } from "react";
import {
  Drawer,
  Grid,
  TextField,
  Button,
  Tooltip,
  IconButton,
  Paper,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import FilterListIcon from "@material-ui/icons/FilterList";
import AppliedFilter from "./AppliedFilter";
import { removeEmptyKey } from "../../utils";
import { backendDateFormat, fieldDateFormat } from "../../constants";
// import { fieldDateFormat, backendDateFormat, displayDateFormat } from '../../../../constants';
const FilterUIComp = ({
  filterMenu,
  filter,
  applyingFilter,
  applyFilter,
  submitFilter,
  removeFilter,
  removeAllFilter,
}) => {
  const [state, setState] = React.useState({ right: false });
  const toggleDrawer = (side, open, triggerFilter) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [side]: open });
    if (triggerFilter) {
      submitFilter();
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="filter-icon">
          <Tooltip title="Filter" arrow>
            <IconButton
              size="small"
              color="primary"
              onClick={toggleDrawer("right", true)}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </div>

        {Object.keys(filter).length ? (
          <AppliedFilter
            removeFilter={removeFilter}
            filterMenu={filterMenu}
            filter={filter}
          />
        ) : null}
      </div>

      {Object.keys(filter).length ? (
        <p onClick={removeAllFilter} className="clear-all-filter">
          Clear Filter
        </p>
      ) : null}

      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
      >
        <div className="plt-filter-header">
          <h3>Apply Filter</h3>
        </div>
        <div className="plt-filter-body">
          <ul className="list-unstyled">
            {Object.keys(filterMenu).map((keyParam, index) => {
              if (
                filterMenu[keyParam].title === "Lessee" ||
                filterMenu[keyParam].title === "Ownership" ||
                filterMenu[keyParam].title === "Portfolio"
              ) {
                return null;
              }
              if (filterMenu[keyParam].inputType == "text") {
                return (
                  <li key={index}>
                    <TextField
                      id={keyParam}
                      label={filterMenu[keyParam].title}
                      fullWidth
                      margin="normal"
                      value={
                        applyingFilter[keyParam] ? applyingFilter[keyParam] : ""
                      }
                      onChange={(e) => applyFilter(keyParam, e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </li>
                );
              } else if (filterMenu[keyParam].inputType == "dropdown") {
                return (
                  <li key={index}>
                    <Autocomplete
                      options={filterMenu[keyParam].options}
                      getOptionLabel={(option) =>
                        option[filterMenu[keyParam].labelKey]
                      }
                      id={keyParam}
                      value={
                        applyingFilter[keyParam] ? applyingFilter[keyParam] : []
                      }
                      onChange={(e, value) => applyFilter(keyParam, value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={filterMenu[keyParam].title}
                          margin="normal"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                      multiple={true}
                    />
                  </li>
                );
              } else if (filterMenu[keyParam].inputType == "date") {
                return (
                  <li key={index} className="date-comp">
                    <h4>{filterMenu[keyParam].title}</h4>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        format={fieldDateFormat}
                        margin="normal"
                        id={filterMenu[keyParam].firstKey}
                        fullWidth
                        placeholder="Date From"
                        InputLabelProps={{ shrink: true }}
                        value={
                          applyingFilter[keyParam] &&
                          applyingFilter[keyParam][
                            filterMenu[keyParam].firstKey
                          ]
                            ? applyingFilter[keyParam][
                                filterMenu[keyParam].firstKey
                              ]
                            : null
                        }
                        onChange={(data, value) =>
                          applyFilter(
                            keyParam,
                            data
                              ? moment(data).format(backendDateFormat)
                              : data,
                            filterMenu[keyParam].firstKey
                          )
                        }
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        format={fieldDateFormat}
                        margin="normal"
                        id={filterMenu[keyParam].lastKey}
                        fullWidth
                        placeholder="Date To"
                        InputLabelProps={{ shrink: true }}
                        value={
                          applyingFilter[keyParam] &&
                          applyingFilter[keyParam][filterMenu[keyParam].lastKey]
                            ? applyingFilter[keyParam][
                                filterMenu[keyParam].lastKey
                              ]
                            : null
                        }
                        onChange={(data, value) =>
                          applyFilter(
                            keyParam,
                            data
                              ? moment(data).format(backendDateFormat)
                              : data,
                            filterMenu[keyParam].lastKey
                          )
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </li>
                );
              }
            })}
          </ul>
        </div>
        <div className="plt-filter-footer">
          {applyingFilter &&
          Object.keys(removeEmptyKey(applyingFilter)).length ? (
            <Button
              size="sm"
              variant="contained"
              onClick={toggleDrawer("right", false, "submitFilter")}
              color="primary"
            >
              <>
                <span>Apply</span>
              </>
            </Button>
          ) : null}

          <Button
            onClick={toggleDrawer("right", false)}
            color="primary"
            variant="contained"
          >
            <>
              <span>Cancel</span>
            </>
          </Button>
        </div>
      </Drawer>
    </>
  );
};
export default FilterUIComp;
