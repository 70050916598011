export const adSmbcFilterOptions = {
    'applicability': {
        'inputType': 'dropdown',
        'title': 'Applicability',
        'options': [],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true
    },
    'ad_compliance_status': {
        'inputType': 'dropdown',
        'title': 'AD Ccompliance Status',
        'options': [{ label: 'OPEN', value: '0' }, { label: 'COMPLIED', value: '1' }, { label: 'PARTIALLY COMPLIED', value: '2' }, { label: 'NOT APPLICABLE', value: '3' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
    'faa_ad': {
        'inputType': 'text',
        'title': 'FAA AD',
    },
    'faa_superseded': {
        'inputType': 'text',
        'title': 'FAA Superseded',
    },
    'easa_ad': {
        'inputType': 'text',
        'title': 'EASA AD',
    },
    'easa_superseded': {
        'inputType': 'text',
        'title': ' EASA Superseded',
    },
}

export const adFilterOptions = {
    'ata': {
        'inputType': 'text',
        'title': 'ATA Chapter',
    },
    'ad_no': {
        'inputType': 'text',
        'title': 'Reference Number',
    },
    'issuing_authority': {
        'inputType': 'text',
        'title': 'Issuing Authority',
    },
    'applicability': {
        'inputType': 'dropdown',
        'title': 'Applicability',
        'options': [],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true
    },
    'ad_compliance_status': {
        'inputType': 'dropdown',
        'title': 'AD Ccompliance Status',
        'options': [{ label: 'OPEN', value: '0' }, { label: 'COMPLIED', value: '1' }, { label: 'PARTIALLY COMPLIED', value: '2' }, { label: 'NOT APPLICABLE', value: '3' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
}

export const sbFilterOptions = {
    'ata_chapter': {
        'inputType': 'text',
        'title': 'ATA Chapter',
    },
    'type': {
        'inputType': 'dropdown',
        'title': 'AD Ccompliance Status',
        'options': [{ label: 'Alert', value: '0' }, { label: 'Standard', value: '1' }, { label: 'Optional', value: '2' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
    'sb_applicability': {
        'inputType': 'dropdown',
        'title': 'Applicability',
        'options': [],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true
    },
    'sb_compliance_status': {
        'inputType': 'dropdown',
        'title': 'SB Ccompliance Status',
        'options': [{ label: 'OPEN', value: '0' }, { label: 'COMPLIED', value: '1' }, { label: 'PARTIALLY COMPLIED', value: '2' }, { label: 'NOT APPLICABLE', value: '3' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
    'sb_ref_number': {
        'inputType': 'text',
        'title': 'SB Ref Number',
    },
}
export const sbSmbcFilterOptions = {
    'sb_applicability': {
        'inputType': 'dropdown',
        'title': 'Applicability',
        'options': [],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true
    },
    'sb_compliance_status': {
        'inputType': 'dropdown',
        'title': 'SB Ccompliance Status',
        'options': [{ label: 'OPEN', value: '0' }, { label: 'COMPLIED', value: '1' }, { label: 'PARTIALLY COMPLIED', value: '2' }, { label: 'NOT APPLICABLE', value: '3' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
    'sb_category': {
        'inputType': 'text',
        'title': 'SB category',
    },
    'sb_ref_number': {
        'inputType': 'text',
        'title': 'SB Ref Number',
    },
}

