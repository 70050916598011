import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const DownloadAllApi = async (item,moduleType) => {
    const attachment_list = item.download_attachments;
    for (var i = 0; i < attachment_list.length; i++) {
        var encodedURL = attachment_list[i][1];
        var decodedURL = decodeURIComponent(encodedURL);
        attachment_list[i][1] = decodedURL;
    }
    const zip = new JSZip();
    const folder = zip.folder('DownloadedFiles');
    try {
        const downloadPromises = attachment_list.map(async (entry) => {
            const name = entry[0];
            const url = entry[1] || '';
            const res = await fetch(url);
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            const blob = await res.blob();
            folder.file(name, blob);
        });

        await Promise.all(downloadPromises);

        const content = await zip.generateAsync({ type: 'blob' });
        saveAs(content, `${moduleType}.zip`);
    } catch (error) {
        console.error('Error:', error);
    }
}