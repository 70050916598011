import React from "react"
import { Tooltip, IconButton, Button } from "@material-ui/core"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"

const DownloadSample = ({ exportSample, files }) => {
  const handleClose = (event, files, DownSample) => {
    if (DownSample) {
      exportSample(files)
    }
  }

  return (
    <>
      {/* <Tooltip title="">
        <IconButton
          variant="outlined"
          color="primary"
          onClick={(event) => handleClose(event, files, "triggerExport")}
          files={{ title: "Download Sample", extension: "xlsx", key: "" }}
        >
          <ExitToAppIcon className="import-task-icon download" />

          {files.extension === "docx"}
          {files.title}
        </IconButton>
      </Tooltip> */}
      <Button
        variant="outlined"
        color="primary"
        onClick={(event) => handleClose(event, files, "triggerExport")}
        files={{ title: "Download Sample", extension: "xlsx", key: "" }}
      >
        {files.title}
      </Button>
    </>
  )
}

export default DownloadSample
