import { regexConstants } from "../../../../constants/regEx";

export const formValues = {
    asset_id: null,
    asset_type_id: null,
    fitment_date: null,
    fitment_operator: null,
    fitment_aircraft: null,
    fitment_tail: null,
    fitment_psn: null,
    fitment_thrust: null,
    fitment_thrust_id: null,
    fitment_aircraft_flying_hours: null,
    fitment_aircraft_flying_cycle: null,
    fitment_engine_tsn: null,
    fitment_engine_csn: null,
    removal_date: null,
    removal_aircraft_flying_hours: null,
    removal_aircraft_flying_cycle: null,
    removal_engine_tsn: null,
    removal_engine_csn: null,
    removal_tslsv: null,
    removal_cslsv: null,
    removal_hours_used: null,
    removal_cycle_used: null,
    removal_egtm: null,
    removal_event: null,
    maint_effective_work_carried_out: null,
    maint_shop: null,
    remarks: null,
    checklist: [],
    folder_uuid: null
};
