import { globalGetService } from "../../utils/globalApiServices"

export function getAircraftDetailApi(queryParams = {}) {
	const { asset, assetType } = queryParams.props.match.params
	return new Promise(function (resolve, reject) {
		globalGetService(`camo/asset/${asset}/${assetType}/view/?module=${queryParams.module}`).then(
			(response) => {
				resolve(response)
			}
		)
	})
}

export function getTechSpecDetailApi(props) {
	globalGetService(`camo/engine_tech_spec/${props?.slug}/details/`)
		.then(response => {
			if (response?.status === 200) {
				this.setState({ basicDetailData: response?.data?.data })
			}
		})
}
