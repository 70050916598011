import React from 'react'
import { Button } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const DownloadPdf = ({exportReportFn, files}) => {

    const handleClose = (event,files, triggerExport) => {
        if(triggerExport){
            exportReportFn(files);
          }
      };

  return (
 <>
   <Button
         color="primary"
         onClick={(event) => handleClose(event,files, 'triggerExport')}
       >
        <>
        <PictureAsPdfIcon />
        {files.extension === 'pdf'}
      {files.title}
        </>
       </Button>
 </>
  )
}

export default DownloadPdf;