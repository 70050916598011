import React, { Component, Fragment, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Drawer, IconButton, Tooltip } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import EmptyCollection from './EmptyCollection';
import PageLoader from './PageLoader';
import RevisionEditCard from './RevisionEditCard';
import RevisionAddCard from './RevisionAddCard';
import { imgStoragePath } from '../../constants';
import { checkApiStatus } from '../../utils';
import { globalGetService } from '../../utils/globalApiServices';
import CloseIcon from '@material-ui/icons/Close';
const RevisionHistory = ({ url, buttonType }) => {
   
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [revisionHistories, setRevisionHistories] = useState([]);
    const getRevisionHistories = () => {
        setLoading(true);
        globalGetService(url)
            .then(response => {
                setLoading(false);
                if (checkApiStatus(response)) {
                    setRevisionHistories(response.data.data)
                    setOpen(true);
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
    }
    return (
        <Fragment>
            {buttonType ?
                <Tooltip title="Revision History" arrow>
                    <HistoryIcon onClick={() => getRevisionHistories(url)} color="primary" fontSize="small" style={{ verticalAlign: "text-top" }} />
                </Tooltip> :
                <span onClick={() => getRevisionHistories(url)}>Revision History</span>
            }
            <Drawer anchor="right" open={open} onClose={() => { setOpen(false); setRevisionHistories([]) }}>
                <div className='revision-history-drawer'>
                    <div className="history-head">
                        <h4>Revision History</h4>
                        <span onClick={() => { setOpen(false); setRevisionHistories([]) }}><CloseIcon /></span>
                    </div>
                    <div className="edit-revision-history" style={{ width: '500px', height: window.innerHeight - 24 + 'px' }}>
                        <div className='history-body'>
                            <ul className="list-unstyled">
                                {revisionHistories.map((item, index) =>
                                    <li>
                                        <span className="timeline-icon"><img src={`${imgStoragePath}${item.action === 1 ? 'edit_audit_icn.png' : 'add_audit_icn.png'}`} alt="icon" /></span>
                                        {item.action === 0 ?
                                            <RevisionAddCard item={item} /> : <RevisionEditCard item={item} />
                                        }
                                    </li>
                                )}
                            </ul>
                        </div>
                        {!revisionHistories.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title="No records found" description="" /></div> : null}
                    </div>
                </div>
            </Drawer>
            {isLoading ? <PageLoader /> : null}
        </Fragment>
    )
}
export default RevisionHistory;

