import React, { Component, Fragment } from "react"
import { withSnackbar } from "notistack"
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Checkbox, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, Tooltip, } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import MomentUtils from "@date-io/moment"
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers"
import moment from "moment"
import { backendDateFormat, fieldDateFormat } from "../../../constants"
import { checkApiStatus, checkLicenseStatus, getLocalStorageInfo, } from "../../../utils"
import { addNewAssetApi } from "../apiServices"
import { fieldValidation } from "../../../utils/formValidation"
import { engineCrud, engineErrorCode } from "../"
import { regexConstants } from "../../../constants/regEx"
import { trackActivity } from "../../../utils/mixpanel"
class AddEngine extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitLoader: false,
      data: engineCrud,
      error: {},
    }
    this.addNewAssetApi = addNewAssetApi.bind(this)
  }
  onFieldChange = (keyParam, value) => {
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [keyParam]: value,
      },
    }))
  }
  onRestErrorKey = (keyParam) => {
    this.setState((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: "",
      },
    }))
  }
  onAddNewAsset = () => {
    const { data } = this.state
    const { addAssetType } = this.props
    // Apply Validation Here
    let validationInputs = {}
    validationInputs = {
      ...validationInputs,
      esn: engineErrorCode["esn"][fieldValidation({ ...engineErrorCode["esnObj"], fieldval: data.esn })],
      engine_type_id: engineErrorCode["engine_type_id"][fieldValidation({ ...engineErrorCode["engine_type_idObj"], fieldval: data.engine_type_id, })],
      status: engineErrorCode["status"][fieldValidation({ ...engineErrorCode["statusObj"], fieldval: data.status, })],
      manufacturing_date: engineErrorCode["manufacturing_date"][fieldValidation({ ...engineErrorCode["manufacturing_dateObj"], fieldval: data.manufacturing_date, })],
      portfolio: data.ownership_type === 2 ? data.owner_portfolio ? "" : "Please select Portfolio" : "",
      region: engineErrorCode["region_of_operation"][fieldValidation({ ...engineErrorCode["region_of_operationObj"], fieldval: data.region, })],
      operator_id: data.same_operator_lessee ? "" : engineErrorCode["operator_id"][fieldValidation({ ...engineErrorCode["operator_idObj"], fieldval: data.operator_id, })],
      lessee_id: data.status && data.status.value == 1 ? engineErrorCode["lessee_id"][fieldValidation({ ...engineErrorCode["lessee_idObj"], fieldval: data.lessee_id, })] : "",
      position: data.status && data.status.value == 1 ? engineErrorCode["position"][fieldValidation({ ...engineErrorCode["positionObj"], fieldval: data.position, })] : "",
    }
    if (addAssetType === "Engine Module") {
      validationInputs = {
        ...validationInputs,
        engine_module_type_id: engineErrorCode["engine_module_type_id"][fieldValidation({ ...engineErrorCode["engine_module_type_idObj"], fieldval: data.engine_module_type_id, })],
      }
    }
    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      let url = ""
      if (addAssetType === "Engine") {
        url = "console/add-engine/" // add Engine console/add-engine/
      } else {
        url = "console/add-" + data.engine_module_type_id.value.toLowerCase() + "/" // add Engine console/add-fan/
      }
      let payload = Object.assign({}, data)
      payload = {
        ...payload,
        engine_type_id: data.engine_type_id.id,
        engine_module_type_id: data.engine_module_type_id && data.engine_module_type_id.value ? data.engine_module_type_id.value : null,
        position: data.position ? data.position.value : null,
        status: data.status.value,
        region: data.region ? data.region.id : null,
        credit_classification: data.credit_classification && data.credit_classification.value ? data.credit_classification.value : null,
        lessee_id: data.lessee_id && data.lessee_id.value ? data.lessee_id.value : null,
        operator_id: data.operator_id && data.operator_id.value ? data.operator_id.value : null,
      }
      if (payload.ownership_type === 2) {
        if (payload.owner_portfolio.id) {
          payload = {
            ...payload,
            owner_portfolio: payload.owner_portfolio.id,
          }
        }
      }
      this.setState({ formSubmitLoader: true })
      this.addNewAssetApi(this.props, url, payload).then((response) => {
        this.setState({ formSubmitLoader: false })
        if (checkApiStatus(response)) {
          this.props.enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
          trackActivity("Item Added", { asset_type: "Engine", page_title: "Asset Listing", })
          window.location.reload()
        }
        if (checkLicenseStatus(response)) {
          this.props.toggleLicenseModalFn(true, response.data.message)
        } else {
          this.props.enqueueSnackbar(response.data.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          })
        }
      })
    } else {
      this.setState({ error: validationInputs })
    }
  }
  render() {
    const { formSubmitLoader, data, error } = this.state
    const { portfolios, regions, lessees, operators, engineTypes, pltConstants, addAssetType, addAssetModal, toggleAssetType, } = this.props
    return (
      <Fragment>
        <Dialog
          open={addAssetModal}
          onClose={() => { toggleAssetType() }}
          aria-labelledby="scroll-dialog-title"
          fullWidth="true"
        >
          <DialogTitle id="scroll-dialog-title">Add {addAssetType}</DialogTitle>
          <DialogContent dividers={true} className="data-upload-modal">
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="esn"
                    label="ESN"
                    fullWidth
                    margin="normal"
                    value={data.esn}
                    error={error.esn ? true : false}
                    helperText={error.esn ? error.esn : ""}
                    onChange={(e, value) => this.onFieldChange("esn", e.target.value)}
                    onFocus={() => this.onRestErrorKey("esn")}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={engineTypes}
                    getOptionLabel={(option) => option.name}
                    id="engine_type_id"
                    value={data.engine_type_id}
                    onChange={(e, value) => this.onFieldChange("engine_type_id", value)}
                    renderInput={(params) => (
                      <TextField
                        required
                        error={error.engine_type_id ? true : false}
                        helperText={error.engine_type_id ? error.engine_type_id : ""}
                        onFocus={() => this.onRestErrorKey("engine_type_id")}
                        {...params}
                        label="Engine Type"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                {addAssetType === "Engine Module" ? (
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={[
                        { label: "FAN", value: "Fan" },
                        { label: "HPC", value: "HPC" },
                        { label: "HPT", value: "HPT" },
                        { label: "LPT", value: "LPT" },
                      ]}
                      getOptionLabel={(option) => option.label}
                      id="engine_module_type_id"
                      value={data.engine_module_type_id}
                      onChange={(e, value) => this.onFieldChange("engine_module_type_id", value)}
                      renderInput={(params) => (
                        <TextField
                          required
                          error={error.engine_module_type_id ? true : false}
                          helperText={error.engine_module_type_id ? error.engine_module_type_id : ""}
                          onFocus={() => this.onRestErrorKey("engine_module_type_id")}
                          {...params}
                          label="Module Type"
                          margin="normal"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      required
                      margin="normal"
                      id="manufacturing_date"
                      label="Date of Manufacture"
                      format={fieldDateFormat}
                      fullWidth
                      disableFuture
                      InputLabelProps={{ shrink: true }}
                      minDate={moment().subtract(25, "years")}
                      value={data.manufacturing_date}
                      error={error.manufacturing_date ? true : false}
                      helperText={error.manufacturing_date ? error.manufacturing_date : ""}
                      onFocus={() => this.onRestErrorKey("manufacturing_date")}
                      onChange={(data, value) => this.onFieldChange("manufacturing_date", moment(data).format(backendDateFormat))}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={pltConstants.filter((item) => item.type === "engine_position")} getOptionLabel={(option) => option.label}
                    id="position"
                    value={data.position}
                    onChange={(e, value) => this.onFieldChange("position", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={data.status && data.status.value === 1 ? true : false}
                        label="Position"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        error={error.position ? true : false}
                        helperText={error.position ? error.position : ""}
                        onFocus={() => this.onRestErrorKey("position")}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={
                      getLocalStorageInfo().defaultLessor.id === 442
                        ? [
                          { label: "Prospect", value: "11" },
                          { label: "In Storage", value: "10" },
                          { label: "Lease Return", value: "9" },
                          { label: "MOU Signed", value: "8" },
                          { label: "MOU Issued", value: "7" },
                          { label: "Lease Issued", value: "6" },
                          { label: "Archive", value: "5" },
                          {
                            label: "Written Off/ Sold / Part out",
                            value: "4",
                          },
                          { label: "OnGround", value: "3" },
                          { label: "Off Lease", value: "2" },
                          { label: "On Lease", value: "1" },
                        ]
                        : [
                          { label: "Archive", value: "5" },
                          { label: "On Lease", value: "1" },
                          { label: "Off Lease", value: "2" },
                          { label: "On Ground", value: "3" },
                          {
                            label: "Written Off/ Sold / Part out",
                            value: "4",
                          },
                        ]
                    }
                    getOptionLabel={(option) => option.label}
                    id="status"
                    value={data.status}
                    onChange={(e, value) => this.onFieldChange("status", value)}
                    renderInput={(params) => (
                      <TextField
                        required
                        error={error.status ? true : false}
                        helperText={error.status ? error.status : ""}
                        onFocus={() => this.onRestErrorKey("status")}
                        {...params}
                        label="Lease Status"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={regions}
                    getOptionLabel={(option) => option.name}
                    id="region"
                    value={data.region}
                    onChange={(e, value) => this.onFieldChange("region", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Region of Operation"
                        required
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        error={error.region ? true : false}
                        helperText={error.region ? error.region : ""}
                        onFocus={() => this.onRestErrorKey("region")}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="location"
                    label="Location"
                    fullWidth
                    margin="normal"
                    value={data.location}
                    onChange={(e, value) => this.onFieldChange("location", e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel
                      className="MuiInputLabel-shrink"
                      component="label"
                    >
                      Ownership Status
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="ownership_type"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value={1}
                        control={
                          <Radio
                            checked={data.ownership_type === 1}
                            onChange={() => this.onFieldChange("ownership_type", 1)}
                            color="primary"
                            size="small"
                          />
                        }
                        label="Owned"
                      />
                      <FormControlLabel
                        value={2}
                        control={
                          <Radio
                            checked={data.ownership_type === 2}
                            onChange={() =>
                              this.onFieldChange("ownership_type", 2)
                            }
                            color="primary"
                            size="small"
                          />
                        }
                        label="Managed"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {data.ownership_type === 2 ? (
                  <Grid item xs={12}>
                    <Autocomplete
                      options={portfolios}
                      getOptionLabel={(option) => option.name}
                      id="owner_portfolio"
                      value={data.owner_portfolio}
                      onChange={(e, value) => this.onFieldChange("owner_portfolio", value)}
                      renderInput={(params) => (
                        <TextField
                          error={error.portfolio ? true : false}
                          required={true}
                          helperText={error.portfolio ? error.portfolio : ""}
                          onFocus={() => this.onRestErrorKey("portfolio")}
                          {...params}
                          label="Portfolio"
                          margin="normal"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={pltConstants.filter((item) => item.type === "credit_classification")}
                    getOptionLabel={(option) => option.label}
                    id="credit_classification"
                    value={data.credit_classification}
                    onChange={(e, value) => this.onFieldChange("credit_classification", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Credit Classification"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date_of_installation"
                      label="Date of Installation"
                      format={fieldDateFormat}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={data.date_of_installation}
                      onChange={(data, value) => this.onFieldChange("date_of_installation", moment(data).format(backendDateFormat))}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="tsn"
                    label="TSN"
                    fullWidth
                    margin="normal"
                    value={data.tsn}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange("tsn", e.target.value) : e.preventDefault() }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="csn"
                    label="CSN"
                    fullWidth
                    margin="normal"
                    value={data.csn}
                    onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange("csn", e.target.value) : e.preventDefault() }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="average_monthly_hours"
                    label="Avg. Monthly Hours"
                    fullWidth
                    margin="normal"
                    value={data.average_monthly_hours}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange("average_monthly_hours", e.target.value) : e.preventDefault() }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="average_monthly_cycles"
                    label="Avg. Monthly Cycles"
                    fullWidth
                    margin="normal"
                    value={data.average_monthly_cycles}
                    onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange("average_monthly_cycles", e.target.value) : e.preventDefault() }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={lessees}
                    getOptionLabel={(option) => option.name}
                    id="lessee_id"
                    value={data.lessee_id}
                    onChange={(e, value) => this.onFieldChange("lessee_id", value)}
                    renderInput={(params) => (
                      <TextField
                        error={error.lessee_id ? true : false}
                        required={data.status && data.status.value === 1 ? true : false}
                        helperText={error.lessee_id ? error.lessee_id : ""}
                        onFocus={() => this.onRestErrorKey("lessee_id")}
                        {...params}
                        label="Lessee"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel
                      className="MuiInputLabel-shrink"
                      component="label"
                    >
                      Is Lessee also the Operator?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="same_operator_lessee"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value={true}
                        control={
                          <Radio
                            onChange={() => { this.onFieldChange("same_operator_lessee", true); this.onRestErrorKey("operator_id") }}
                            checked={data.same_operator_lessee}
                            color="primary"
                            size="small"
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={
                          <Radio
                            onChange={() => { this.onFieldChange("same_operator_lessee", false); this.onRestErrorKey("operator_id") }}
                            checked={!data.same_operator_lessee}
                            color="primary"
                            size="small"
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {!data.same_operator_lessee ? (
                  <Grid item xs={12}>
                    <Autocomplete
                      options={operators}
                      getOptionLabel={(option) => option.name}
                      id="operator_id"
                      value={data.operator_id}
                      onChange={(e, value) => this.onFieldChange("operator_id", value)}
                      renderInput={(params) => (
                        <TextField
                          error={error.operator_id ? true : false}
                          required={!data.same_operator_lessee ? true : false}
                          helperText={
                            error.operator_id ? error.operator_id : ""
                          }
                          onFocus={() => this.onRestErrorKey("operator_id")}
                          {...params}
                          label="Operator"
                          margin="normal"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} md={6}>
                  <TextField
                    id="operating_thrust"
                    label="Operating Thrust (Lbs)"
                    fullWidth
                    margin="normal"
                    value={data.operating_thrust}
                    onChange={(e) => {
                      regexConstants.numberWithDot.test(e.target.value)
                        ? this.onFieldChange("operating_thrust", e.target.value)
                        : e.preventDefault()
                    }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="purchase_thrust"
                    label="Purchased Thrust (Lbs)"
                    fullWidth
                    margin="normal"
                    value={data.purchase_thrust}
                    onChange={(e) => {
                      regexConstants.numberWithDot.test(e.target.value)
                        ? this.onFieldChange("purchase_thrust", e.target.value)
                        : e.preventDefault()
                    }}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data.match_csn_to_llp}
                        onChange={(e) =>
                          this.onFieldChange(
                            "match_csn_to_llp",
                            e.target.checked
                          )
                        }
                        color="primary"
                        size="small"
                      />
                    }
                    label="Match Engine's TSN & CSN to LLP"
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => { toggleAssetType(); trackActivity("Item Clicked", { page_title: "Asset Listing", asset_type: "Engine", activity_type: "Cancel", }) }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.onAddNewAsset}
            >
              {formSubmitLoader ? (<CircularProgress color="#ffffff" size={24} />) : ("Save")}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}
export default withSnackbar(AddEngine)
