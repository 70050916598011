import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
class Home extends Component {
  componentDidMount() {
  }
  render() {
    return (
      <section className="asset-listing-section">
        Home
      </section>
    )
  }
}
const mapStateToProps = state => ({
  aircraftTypes: state.shareReducer.aircraftTypes,
  engineTypes: state.shareReducer.engineTypes,
  lessees: state.shareReducer.lessees,
  operators: state.shareReducer.operators,
  regions: state.shareReducer.regions,
  portfolios: state.shareReducer.portfolios,
  pltConstants: state.shareReducer.pltConstants,
  aircraftMntGrp: state.shareReducer.aircraftMntGrp
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Home))
