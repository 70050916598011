import {
  globalGetService,
  globalPostService,
} from "../utils/globalApiServices";
import * as actions from "./actions";
import { checkApiStatus } from "../utils";
export const getRegionListAc = (props = {}, query = {}) => {
  return (dispatch) => {
    globalGetService(`console/regions/`, query).then((response) => {
      if (checkApiStatus(response)) {
        dispatch({
          type: actions.REGION_LIST,
          payload: response.data.data.region_list,
        });
      }
    });
  };
};

export const getPortfolioListAc = (query) => {
  return (dispatch) => {
    globalGetService(`console/owner/`).then((response) => {
      if (checkApiStatus(response)) {
        dispatch({
          type: actions.PORTFOLIO_LIST,
          payload: response.data.data,
        });
      }
    });
  };
};
export const getEngineTypesAc = (query = {}) => {
  return (dispatch) => {
    globalGetService(`console/engine-types/`, query).then((response) => {
      if (checkApiStatus(response)) {
        dispatch({
          type: actions.ENGINE_TYPES,
          payload: response.data.data.engineTypes,
        });
      }
    });
  };
};
export const getAircraftTypesAc = (props = {}, query = {}) => {
  return (dispatch) => {
    globalGetService(`console/aircraft-types/`, query).then((response) => {
      if (checkApiStatus(response)) {
        dispatch({
          type: actions.AIRCRAFT_TYPES,
          payload: response.data.data.aircraftTypes,
        });
      }
    });
  };
};
export const getAircraftMntProgramAc = (aircraftId) => {
  return (dispatch) => {
    globalGetService(`console/aircraft-type/${aircraftId}/events/`).then(
      (response) => {
        if (checkApiStatus(response)) {
          let maintanenceList = [];
          const data = response.data.data;
          Object.keys(data).map((item, index) => {
            maintanenceList.push({
              label: data[item].replace(/,/g, "/") + "",
              value: item,
            });
          });
          dispatch({
            type: actions.AIRCRAFT_MNT_GRP,
            payload: maintanenceList,
          });
        }
      }
    );
  };
};
export const getAPUTypesAc = (query = {}) => {
  return (dispatch) => {
    globalGetService(`console/apu-types/`, query).then((response) => {
      if (checkApiStatus(response)) {
        dispatch({
          type: actions.APU_TYPES,
          payload: response.data.data.apu_types,
        });
      }
    });
  };
};

export const getLessorListAc = (props = {}, query = {}) => {
  return (dispatch) => {
    globalGetService(`console/lessors/`, query).then((response) => {
      if (checkApiStatus(response)) {
      }
    });
  };
};
export const getLesseeListAc = (props = {}, query = {}) => {
  return (dispatch) => {
    globalGetService(`console/lessees/`, query).then((response) => {
      if (checkApiStatus(response)) {
        dispatch({
          type: actions.LESSEE_LIST,
          payload: response.data.data.lessees,
        });
      }
    });
  };
};
export const getOperatorListAc = (props = {}, query = {}) => {
  return (dispatch) => {
    globalGetService(`console/operators/`, query).then((response) => {
      if (checkApiStatus(response)) {
        dispatch({
          type: actions.OPERATOR_LIST,
          payload: response.data.data.operators,
        });
      }
    });
  };
};
export const getManufacturersAc = (props = {}, query = {}) => {
  return (dispatch) => {
    globalGetService(`console/manufacturers/`, query).then((response) => {
      if (checkApiStatus(response)) {
        dispatch({
          type: actions.MANUFACTURER_LIST,
          payload: response.data.data,
        });
      }
    });
  };
};

export const getPlatformConstantsAc = (data) => {
  return (dispatch) => {
    globalPostService(`console/get-constants/`, data).then((response) => {
      if (checkApiStatus(response)) {
        dispatch({
          type: actions.PLATFORM_CONTANTS,
          payload: response.data.data,
        });
      }
    });
  };
};

export const getAllAssetListAc = (props = {}, query = {}) => {
  return (dispatch) => {
    globalGetService(`console/list/`, { switcher: true }).then((response) => {
      if (checkApiStatus(response)) {
        dispatch({
          type: actions.ALL_ASSETS,
          payload: response.data.data.asset,
        });
      }
    });
  };
};

export const getLeaseStatus = (data) => {
  return (dispatch) => {
    globalPostService(`console/get-constants/`, data).then((response) => {
      if (checkApiStatus(response)) {
        dispatch({
          type: actions.LEASE_STATUS,
          payload: response.data.data,
        });
      }
    });
  };
};

export const getSubStatus = (data) => {
  return (dispatch) => {
    globalPostService(`console/get-constants/`, data).then((response) => {
      if (checkApiStatus(response)) {
        dispatch({
          type: actions.SUB_STATUS,
          payload: response.data.data,
        });
      }
    });
  };
};
