import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import JSONViewer from 'react-json-viewer';
import LabelValueCard from './LabelValueCard';
const RevisionAddCard = ({ item }) => {
  const timestamp = new Date(item.timestamp);
  const year = timestamp.getFullYear();
  const month = (timestamp.getMonth() + 1).toString().padStart(2, "0");
  const day = timestamp.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return (
    <Fragment>
      <Grid container spacing={3}>
        <LabelValueCard md={6} label='Created at' value={formattedDate} />
        <LabelValueCard md={6} label='Created by' value={item.user && item.user.name ? item.user.name : '--'} />
      </Grid>
      <div className="edit-detail-table">
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          {Object.entries(item.changes).map(([item, value], index) =>
            <tbody>
              <tr key={index}>
                <td style={{ textTransform: 'capitalize' }}>{item.trim().includes('_') ? item.trim().replace(/[_]/g, ' ') : item}</td>
                <td>{value}</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </Fragment>
  )
}
export default RevisionAddCard;
