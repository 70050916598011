// import Login from "./containers/Login"
// import LessorSelection from "./containers/LessorSelection"
// import userLoggedCheckHoc from "../../hocs/userLoggedCheckHoc"
// export const authRoutes = [
//   {
//     path: "/",
//     component: userLoggedCheckHoc(Login),
//     key: "login",
//     apps: "Auth",
//   },
//   {
//     path: "/login",
//     component: userLoggedCheckHoc(Login),
//     key: "login1",
//     apps: "Auth",
//   },
//   {
//     path: "/select-lessor",
//     component: userLoggedCheckHoc(LessorSelection),
//     key: "select-lessor",
//     apps: "Auth",
//   },
// ]
import Login from "./containers/Login"
export const authRoutes = [
  {
    path: "/login",
    component: Login,
    key: "login1",
    apps: "Auth",
  }
]