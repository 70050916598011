import React from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
const filter = createFilterOptions();
const SectionCreates = ({ options, paramsKey, value, onFieldChange, resetErrorKey, optionKey, label, required, error, multiple = false, helperText, disabled }) => {
    return(
        <Autocomplete
          value={value}
          onChange={(e, newValue) => {
            if (typeof newValue === 'string') {
              onFieldChange(e, paramsKey, {[optionKey]:newValue})
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              onFieldChange(e, paramsKey, {[optionKey]:newValue.inputValue})
            } else {
              onFieldChange(e, paramsKey, newValue)
            }
          }}
          options = {options}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            // Suggest the creation of a new value
            if (filtered.length === 0 && params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                [optionKey]: `Add "${params.inputValue}"`,
              });
            }
            return filtered;
          }}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option?.name;
          }}
          renderOption={(option) => option[optionKey]}
          id={paramsKey}
          freeSolo={false}
          renderInput={params => <TextField required={required}  error={error ? true:false} helperText={error?error:''} {...params} onFocus={resetErrorKey} label={label} margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
          multiple={multiple}
          disabled={disabled}
        />
      )
}
export default SectionCreates