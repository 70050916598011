import { getLocalStorageInfo } from "../../../../utils"

export const ampTableMainHead = [
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },

  { id: "Threshold", label: "Threshold", sortOption: false, colspan: 3 },
  { id: "Interval", label: "Interval", sortOption: false, colspan: 3 },
  { id: "Last Done", label: "Last Done", sortOption: false, colspan: 3 },
  { id: "Next Due", label: "Next Due", sortOption: false, colspan: 3 },
  { id: "Remaining", label: "Remaining", sortOption: false, colspan: 3 },

  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
]

export const AmpTableHead = [
  { id: "actions", label: "Action", sortOption: false },
  { id: "MPD_item_no", label: "MPD Item no.", sortOption: true },
  { id: "task_card_no", label: "Task Card no.", sortOption: true },
  {
    id: "previous_LDND_Taskcard",
    label: "Previous LDND TaskCard",
    sortOption: true,
  },
  {
    id: "Task_Description",
    label: "Task Description",
    sortOption: true,
    width: 200,
  },
  { id: "thresHold_d", label: "Days", sortOption: false },
  { id: "thresHold_fh", label: "FH", sortOption: false },
  { id: "thresHold_fc", label: "FC", sortOption: false },

  { id: "thresHold_d", label: "Days", sortOption: false },
  { id: "thresHold_fh", label: "FH", sortOption: false },
  { id: "thresHold_fc", label: "FC", sortOption: false },

  { id: "thresHold_d", label: "Date", sortOption: false },
  { id: "thresHold_fh", label: "FH", sortOption: false },
  { id: "thresHold_fc", label: "FC", sortOption: false },

  { id: "thresHold_d", label: "Date", sortOption: false },
  { id: "thresHold_fh", label: "FH", sortOption: false },
  { id: "thresHold_fc", label: "FC", sortOption: false },

  { id: "thresHold_d", label: "Days", sortOption: false },
  { id: "thresHold_fh", label: "FH", sortOption: false },
  { id: "thresHold_fc", label: "FC", sortOption: false },

  { id: "remarks", label: "Remarks" },
  { id: "attachments", label: "Attachments" },
]

export const assetFilterOps = {
  aircraft_type: {
    inputType: "dropdown",
    placeholder: "Select Aircraft Type",
    title: "Aircraft Type",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  engine_type: {
    inputType: "dropdown",
    placeholder: "Select Engine Type",
    title: "Engine Type",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  lessee: {
    inputType: "dropdown",
    placeholder: "Select Lessee",
    title: "Lessee",
    options: [],
    labelKey: "name",
    valueKey: "slug",
    multiple: true,
  },
  lessor_name: {
    inputType: "text",
    placeholder: "Enter Lessor",
    title: "Lessor",
  },
  owner: {
    inputType: "text",
    placeholder: "Enter Owner ",
    title: "Owner",
  },
  ownership_type: {
    inputType: "dropdown",
    placeholder: "Select Ownership Type",
    title: "Ownership",
    options: [
      { label: "Owned", value: 1 },
      { label: "Managed", value: 2 },
    ],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
  registration: {
    inputType: "text",
    placeholder: "Enter Registeration Number",
    title: "Registeration Number",
  },
  portfolio: {
    inputType: "text",
    placeholder: "Enter Portfolio",
    title: "Portfolio",
  },
  serial_number: {
    inputType: "text",
    placeholder: "Enter Serial Number",
    title: "Serial Number",
  },
  status: {
    inputType: "dropdown",
    placeholder: "Select Status",
    title: "Status",
    options:
      getLocalStorageInfo() &&
      getLocalStorageInfo().defaultLessor &&
      getLocalStorageInfo().defaultLessor.id === 442
        ? [
            { label: "Prospect", value: "11" },
            { label: "In Storage", value: "10" },
            { label: "Lease Return", value: "9" },
            { label: "MOU Signed", value: "8" },
            { label: "MOU Issued", value: "7" },
            { label: "Lease Issued", value: "6" },
            { label: "Archive", value: "5" },
            { label: "Written Off/ Sold / Part out", value: "4" },
            { label: "OnGround", value: "3" },
            { label: "Off Lease", value: "2" },
            { label: "On Lease", value: "1" },
          ]
        : [
            { label: "Archive", value: "5" },
            { label: "On Lease", value: "1" },
            { label: "Off Lease", value: "2" },
            { label: "On Ground", value: "3" },
            { label: "Written Off/ Sold / Part out", value: "4" },
          ],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
}
