import { regexConstants } from "../../../../constants/regEx";

export const formValues = {
	asset_id: null,
	asset_type_id: null,
	MPD_item_no: null,
	task_card_no: null,
	previous_LDND_Taskcard: null,
	Task_Description: "",
	threshold_D: null,
	threshold_FH: null,
	threshold_FC: null,
	interval_D: null,
	interval_FH: null,
	interval_FC: null,
	lastDone_D: null,
	lastDone_FH: null,
	lastDone_FC: null,
	remainingValues_D: null,
	remainingValues_FH: null,
	remainingValues_FC: null,
	remarks: null,
	workOrder: "",
	attachments: [],
	nextDue_D: null,
	nextDue_FH: null,
	nextDue_FC: null,
	checklist: [],
	folder_uuid: '',
};

export const taskErrorCode = {
	MPD_item_no: {
		0: "",
		1: "Required",
	},
	MPD_item_no_obj: {
		required: true,
		//regexPattern: regexConstants.onlyNumeric,
	},
	task_card_no: {
		0: "",
		1: "Required",
	},
	task_card_no_obj: {
		required: true,
		//regexPattern: regexConstants.onlyNumeric,
	},
	Task_Description: {
		0: "",
		1: "Required",
	},
	Task_Description_obj: {
		required: true,
	},
	threshold_D: {
		0: "",
		1: "Required",
		4: "Invalid Format",
	},
	threshold_D_obj: {
		required: true,
		regexPattern: regexConstants.onlyNumeric,
	},
	threshold_FH: {
		0: "",
		1: "Required",
	},
	threshold_FH_obj: {
		required: true,
		regexPattern: regexConstants.onlyNumeric,
	},
	threshold_FC: {
		0: "",
		1: "Required",
	},
	threshold_FC_obj: {
		required: true,
		regexPattern: regexConstants.onlyNumeric,
	},
	interval_D: {
		0: "",
		1: "Required",
		4: "Invalid Format",
	},
	interval_D_obj: {
		required: true,
		regexPattern: regexConstants.onlyNumeric,
	},
	interval_FH: {
		0: "",
		1: "Required",
	},
	interval_FH_obj: {
		required: true,
		regexPattern: regexConstants.onlyNumeric,
	},
	interval_FC: {
		0: "",
		1: "Required",
	},
	interval_FC_obj: {
		required: true,
		regexPattern: regexConstants.onlyNumeric,
	},
	lastDone_D: {
		0: "",
		1: "Required",
		4: "Invalid Format",
		5: "Please enter valid date",
	},
	lastDone_D_obj: {
		required: true,
		datePattern: true,
	},
	lastDone_FH: {
		0: "",
		1: "Required",
	},
	lastDone_FH_obj: {
		required: true,
		regexPattern: regexConstants.onlyNumeric,
	},
	lastDone_FC: {
		0: "",
		1: "Required",
	},
	lastDone_FC_obj: {
		required: true,
		regexPattern: regexConstants.onlyNumeric,
	},
	remarks: {
		0: "",
		1: "Required",
	},
	remarks_obj: {
		required: true,
	},
	workOrder: {
		0: "",
		1: "Required",
	},
	workOrder_obj: {
		required: true,
	},
};
