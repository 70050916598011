import React, { Component } from "react"
import { getGlobalCookie } from '../../../utils'
import { Redirect } from "react-router-dom";
import config from '../../../config'
class Login extends Component {
  componentDidMount(){
    if(getGlobalCookie('lessorAccess')){
      <Redirect to="/assets-listing" />
    }else{
       window.location.href = `${config.domain.subDomian}login?apps=camo`;
    }
  }
  render() {
    return(
      <div></div>
    )
  }
}

export default Login




